import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import HomeTwo from './pages/homepages/HomeTwo';
import Error from './pages/innerpages/Error';

// Import Css Here 
import './assets/scss/style.scss';
import ThankYouPage from './pages/homepages/ThankYou';
import MasterPgm from './pages/homepages/MasterPgm';

function App() {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path='/' element={<HomeTwo/>}/>
                    <Route exact path='/thankyou' element={<ThankYouPage />}/>
                    <Route exact path='/master-programs' element={<HomeTwo/>}/>
                    <Route exact path='/bachelors-programs' element={<HomeTwo/>}/>
                    <Route exact path='/phd-programs' element={<HomeTwo/>}/>
                    <Route exact path='/dba-program' element={<HomeTwo/>}/>

                    <Route path='*' element={<Error/>}/>
                </Routes>
            </ScrollToTop>
        </Router>
    );
}

export default App;

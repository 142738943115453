import { useState, useEffect } from "react";
import { Link } from "react-scroll";
// import ResponsiveMenu from "./ResponsiveMenu";
import CallIcon from "@mui/icons-material/Call";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import MenuIcon from "@mui/icons-material/Menu"; // Import Hamburger icon
import CloseIcon from "@mui/icons-material/Close";
import "animate.css";

const HeaderTwo = ({ styles, disableSticky, searchDisable, buttonStyle }) => {
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(false); // State for side menu

  const onCanvasHandler = () => {
    setOffcanvasShow((prevState) => !prevState);
  };

  const onSearchHandler = () => {
    setSearchPopup((prevState) => !prevState);
  };

  const toggleSideMenu = () => {
    setSideMenuOpen((prevState) => !prevState); // Toggle side menu
  };

  // Close the drawer if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideMenuOpen && !event.target.closest(".side-menu")) {
        setSideMenuOpen(false); // Close if clicked outside the menu
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sideMenuOpen]);

  if (searchPopup) {
    document.body.classList.add("search-popup-active");
  } else {
    document.body.classList.remove("search-popup-active");
  }

  const [alertVisible, setAlertVisible] = useState(true);


  return (
    <>
      <header className={`edu-header  ${styles || ""}`}>
        <div
          className="row align-items-center"
          style={{ justifyContent: "space-between" }}
        >
          <div className="col-lg-4 col-xl-3 col-md-6 col-6">
            <div className="logo">
              <Link to={""}>
                <img
                  className="logo-light"
                  src="/images/brand-logos/PAIU.png"
                  alt="Main Logo"
                />
              </Link>
            </div>
          </div>

          <div className="col-lg-8 col-xl-6 col-md-6 col-6">
            <div className="header-right d-flex justify-content-end">
              {/* Email and phone for larger screens */}
              <div className="header-menu-bar d-none d-lg-flex">
                <div className="px-3 fs-2">
                  <a href="tel:+971569456777" style={{color: "#0c0d77"}}>
                    <CallIcon sx={{ color: "#0c0d77", fontSize: "20px" }} /> +971 56 945 6777
                  </a>
                </div>
                <div className="px-3 fs-2">
                  <a href="mailto:admissions@paiu.ae" style={{color: "#0c0d77"}}>
                    <MarkunreadIcon
                      sx={{ color: "#0c0d77", fontSize: "20px" }}
                    />{" "}
                    admissions@paiu.ae
                  </a>
                </div>

                <div className="quote-icon quote-user d-none d-md-block ml--15 ml_sm--5">
                  <Link to="contact-us" spy={true} smooth={true} duration={200}>
                    <button className="btnn">
                      <i className="animation"></i>Apply for scholarship
                      <i className="animation"></i>
                    </button>
                  </Link>
                </div>
              </div>

              {/* Hamburger icon for small screens */}
              <div className="d-lg-none">
                <button
                  className="hamberger-button"
                  onClick={toggleSideMenu}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {sideMenuOpen ? (
                    <CloseIcon sx={{ fontSize: "35px", color: "#0c0d77" }} />
                  ) : (
                    <MenuIcon sx={{ fontSize: "35px", color: "#0c0d77" }} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Full-width drawer from top under the hamburger */}
      {sideMenuOpen && (
        <div
          className="side-menu"
          style={{
            position: "absolute",
            top: "60px", // Just below the header
            left: 0,
            width: "100%",
            backgroundColor: "#fff",
            zIndex: 1000,
            padding: "20px",
            transition: "transform 0.3s ease-in-out",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="side-menu-content" style={{ textAlign: "center" }}>
            <div className="mb-4">
              <a href="tel:+971569456777" style={{ fontSize: "18px",color: "#0c0d77" }}>
                <CallIcon sx={{ color: "#0c0d77", fontSize: "20px" }} /> +971 56 945 6777
              </a>
            </div>
            <div className="mb-4">
              <a href="mailto:admissions@paiu.ae" style={{ fontSize: "18px",color: "#0c0d77" }}>
                <MarkunreadIcon
                  sx={{ color: "#0c0d77", fontSize: "20px" }}
                />{" "}
                admissions@paiu.ae
              </a>
            </div>
          </div>
        </div>
      )}
{/* 
      <ResponsiveMenu
        show={offcanvasShow}
        onClose={onCanvasHandler}
        showSearch={searchPopup}
        onSearch={onSearchHandler}
      /> */}
       {alertVisible && (
        <div class="alert-container animate__animated animate__fadeInDown">
          <div
            className="alert alert-warning alert-dismissible fade show text-center"
            role="alert"
          >
            <strong>50% DISCOUNT!</strong> <br />
            Hurry Up and Grab the Offer!
            <br />
            Don't miss out on this exclusive discount. Visit our website to
            learn more and take advantage of this limited-time offer.
            <button
              onClick={() => setAlertVisible(false)}
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderTwo;

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";

const CustomForm = ({ formStyle }) => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    course_title: "",
    country: "",
  });

  const [errors, setErrors] = useState({});
  const [result, setResult] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIPAddress();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.fullname) {
      newErrors.fullname = "Please fill out your name.";
    }
    if (!formData.email) {
      newErrors.email = "Please fill out your email.";
    }
    if (!formData.phone) {
      newErrors.phone = "Please fill out your phone number.";
    }
    if (!formData.course_title) {
      newErrors.course_title = "Please select a course.";
    }
    if (!formData.country) {
      newErrors.country = "Please select a country.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const portalId = "143713869"; // Replace with your portalId
    const formId = "6e5e77c2-39c1-4e31-850e-b1dcd8abccac"; // Replace with your formId

    const payload = {
      fields: [
        { name: "firstname", value: formData.fullname },
        { name: "email", value: formData.email },
        { name: "phone", value: formData.phone },
        { name: "field_of_study", value: formData.course_title }, // Updated field name
        { name: "country_i_live_in", value: formData.country },
        // Updated field name
      ],
      context: {
        ipAddress: ipAddress, // Add the IP address here
      },
    };

    const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        window.location.href = "/thankyou";
        setResult(true);
        setFormData({
          fullname: "",
          email: "",
          phone: "",
          course_title: "",
          country: "",
        });
        // setTimeout(() => setResult(false), 5000);
      } else {
        alert("There was an error submitting the form.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error submitting the form.");
    }
  };

  const location = useLocation();

  const getFilteredCourses = () => {
    if (location.pathname.includes("/bachelors-programs")) {
      return [
        {
          value: "PAIU ( Bachelors in Accounting & Business )",
          label: "Bachelor's in Accounting & Business",
        },
        {
          value: "PAIU (Bachelor's of Business Administration )",
          label: "Bachelor's in Business Administration",
        },
        {
          value: "PAIU ( BSc in Cyber Security )",
          label: "Bachelor's in Cyber Security",
        },
        {
          value: "PAIU ( BSc in Data Science )",
          label: "Bachelor's in Data Science",
        },
        {
          value: "PAIU ( Bachelor's in Hospitality & Tourism )",
          label: "Bachelor's in Hospitality & Tourism",
        },
        {
          value: "PAIU ( Information Technology )",
          label: "Bachelor's in Information Technology",
        },
      ];
    } else if (location.pathname.includes("/phd-programs")) {
      return [
        {
          value: "PhD in Data and Cybersecurity",
          label: "PhD in Data and Cybersecurity",
        },
        {
          value: "phd_in_demographics___social_science",
          label: "PhD in Demographics & Social Science",
        },
        { value: "PhD in Economics", label: "PhD in Economics" },
        { value: "PhD in Education", label: "PhD in Education" },
        { value: "phd_in_history", label: "PhD in History" },
        {
          value: "PhD in International Relations",
          label: "PhD in International Relations",
        },
        { value: "PhD in Law", label: "PhD in Law" },
        {
          value: "PhD in Sales and Marketing",
          label: "PhD in Sales and Marketing",
        },
        { value: "phd_in_tesol", label: "PhD in TESOL" },
      ];
    } else if (location.pathname.includes("/dba-program")) {
      return [
        { value: "DBA", label: "DBA - Doctorate of Business Administration" },
      ];
    }
    // Default for other paths
    return [
      { value: "MSc in Data Science", label: "Masters in Data Science" },
      { value: "MSc in Cyber Security", label: "Masters in Cyber Security" },
      {
        value: "MBA ( Corporate Finance )",
        label: "Masters in Corporate Finance",
      },
      {
        value: "MSc in Information Technology",
        label: "Masters in Information Technology",
      },
      {
        value: "MBA ( Marketing Management )",
        label: "Masters in Marketing Management",
      },
      { value: "MBA (on-campus)", label: "Masters in Business Administration" },
      {
        value: "MBA ( Legal Business Studies )",
        label: "Masters in Legal Business Studies",
      },
      {
        value: "MBA ( Human Resource Management )",
        label: "Masters in Human Resource Management",
      },
    ];
  };

  return (
    <form
      className={`${formStyle}`}
      onSubmit={handleSubmit}
      // style={{ paddingTop: "70px" }}
    >
      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className={`form-control form-control-lg ${
              errors.fullname ? "border-red-500" : ""
            }`}
            name="fullname"
            placeholder="Name*"
            value={formData.fullname}
            onChange={handleChange}
            required
          />
          {errors.fullname && <p className="text-danger">{errors.fullname}</p>}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="email"
            className={`form-control form-control-lg ${
              errors.email ? "text-danger" : ""
            }`}
            name="email"
            placeholder="Email*"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="text-danger">{errors.email}</p>}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <PhoneInput
            country={"ae"}
            value={formData.phone}
            onChange={handlePhoneChange}
            // inputClass={`form-control form-control-lg ${
            //   errors.phone ? "border-red-500" : ""
            // }`}
            required
          />
          {errors.phone && (
            <p className="text-danger text-sm">{errors.phone}</p>
          )}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <Form.Select
            name="course_title"
            value={formData.course_title}
            onChange={handleChange}
            className={`form-control custom-select form-control-lg ${
              errors.course_title ? "border-red-500" : ""
            }`}
            required
          >
            <option value="">Select Course</option>
            {/* <option value="MSc in Data Science">Masters in Data Science</option>
            <option value="MSc in Cyber Security">
              Masters in Cyber Security
            </option>
            <option value="MBA ( Corporate Finance )">
              Masters in Corporate Finance
            </option>
            <option value="MSc in Information Technology">
              Masters in Information Technology
            </option>
            <option value="MBA ( Marketing Management )">
              Masters in Marketing Management
            </option>
            <option value="MBA (on-campus)">
              Masters in Business Administration
            </option>
            <option value="MBA ( Legal Business Studies )">
              Masters in Legal Business Studies
            </option>
            <option value="MBA ( Human Resource Management )">
              Masters in Human Resource Management
            </option>
            <option value="MBA ( Human Resource Management )">
              Bachelors in Human Resource Management
            </option> */}
            {getFilteredCourses().map((course) => (
              <option key={course.value} value={course.value}>
                {course.label}
              </option>
            ))}
          </Form.Select>
          {errors.course_title && (
            <p className="text-danger text-sm">{errors.course_title}</p>
          )}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <Form.Select
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="custom-select"
          >
            <option value="">Select Country</option>
            {/* <option value="UAE">UAE</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Qatar">Qatar</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Bahrain">Bahrain</option>
            <option value="India">India</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Nepal">Nepal</option>
            <option value="Kenya">Kenya</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Maldives">Maldives</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Pakistan">Pakistan</option> */}
            <option value="Afghanistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">
              Bosnia and Herzegovina
            </option>
            <option value="Botswana">Botswana</option>
            <option value="Brazil">Brazil</option>
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cabo Verde">Cabo Verde</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Central African Republic">
              Central African Republic
            </option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo (Congo-Brazzaville)">
              Congo (Congo-Brazzaville)
            </option>
            <option value="Congo (Democratic Republic)">
              Congo (Democratic Republic)
            </option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Eswatini">Eswatini</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Greece">Greece</option>
            <option value="Grenada">Grenada</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Honduras">Honduras</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran">Iran</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libya">Libya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia">Micronesia</option>
            <option value="Moldova">Moldova</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="North Korea">North Korea</option>
            <option value="North Macedonia">North Macedonia</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Qatar">Qatar</option>
            <option value="Romania">Romania</option>
            <option value="Russia">Russia</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Vincent and the Grenadines">
              Saint Vincent and the Grenadines
            </option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia">Serbia</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Korea">South Korea</option>
            <option value="South Sudan">South Sudan</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syria">Syria</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Togo">Togo</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Vatican City">Vatican City</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </Form.Select>
          {errors.country && <p className="text-danger">{errors.country}</p>}
        </div>
      </div>

      <div className="col-lg-12">
        <button className="rn-btn edu-btn w-100" type="submit">
          <span>Submit</span>
          {/* <i className="icon-arrow-right-line-right"></i> */}
        </button>
      </div>

      {/* {result ? (
        <div className="form-group">
          <p className="success-message">Thanks for your query. We will contact you soon.</p>
        </div>
      ) : null} */}
    </form>
  );
};

export default CustomForm;

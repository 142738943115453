import { Box, Card, CardContent, Stack } from "@mui/material";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import ForumIcon from "@mui/icons-material/Forum";
import SchoolIcon from "@mui/icons-material/School";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SupportIcon from '@mui/icons-material/Support';import VerifiedIcon from "@mui/icons-material/Verified";
import ClassIcon from "@mui/icons-material/Class";

const FeatureItems = [
  {
    title: "Master's in Business Administration (MBA)",
    info: "Our MBA program at PAIU is designed to transform you into a global business leader. Through a curriculum that integrates both theoretical and practical knowledge, you will gain insights into key business disciplines including finance, marketing, operations, and strategic management.This comprehensive approach ensures you are well-prepared to tackle the complexities of the modern business world",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "masters", 
  },
  {
    title: "Masters in Data Science",
    info: "Our MSc in Data Science equips you with the necessary skills to analyze and interpret complex data. This program focuses on machine learning, statistical methods, data visualization, and data-driven decision-making, ensuring you are ready to meet the demands of this rapidly evolving field.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "masters", 
  },
  {
    title: "Masters in Information Technology",
    info: "The MSc in Information Technology at PAIU provides an in-depth understanding of IT systems, software development, and IT project management. This program is ideal for those looking to enhance their technical skills and pursue a career in the ever-evolving tech industry.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "masters", 
  },
  {
    title: "Masters in Cyber Security",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "masters", 
  },
  {
    title: "Masters in Corporate Finance",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "masters", 
  },
  {
    title: "Masters in Marketing Management",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "masters", 
  },
  {
    title: "Masters in Legal Business Studies",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "masters", 
  },
  {
    title: "Masters in Human Resource Management",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "masters", 
  },

  {
    title: "Bachelor's in Accounting & Business",
    info: "Our MBA program at PAIU is designed to transform you into a global business leader. Through a curriculum that integrates both theoretical and practical knowledge, you will gain insights into key business disciplines including finance, marketing, operations, and strategic management.This comprehensive approach ensures you are well-prepared to tackle the complexities of the modern business world",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "bachelors",
  },
  {
    title: "Bachelor's in Business Administration",
    info: "Our MSc in Data Science equips you with the necessary skills to analyze and interpret complex data. This program focuses on machine learning, statistical methods, data visualization, and data-driven decision-making, ensuring you are ready to meet the demands of this rapidly evolving field.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "bachelors",
  },
  {
    title: "Bachelor's in Cyber Security",
    info: "The MSc in Information Technology at PAIU provides an in-depth understanding of IT systems, software development, and IT project management. This program is ideal for those looking to enhance their technical skills and pursue a career in the ever-evolving tech industry.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "bachelors",
  },
  {
    title: "Bachelor's in Data Science",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "bachelors",
  },
  {
    title: "Bachelor's in Hospitality & Tourism",
    info: "The MSc in Information Technology at PAIU provides an in-depth understanding of IT systems, software development, and IT project management. This program is ideal for those looking to enhance their technical skills and pursue a career in the ever-evolving tech industry.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "bachelors",
  },
  {
    title: "Bachelor's in Information Technology",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "bachelors", 
  },

  {
    title: "PhD in Data and Cybersecurity",
    info: "Our MBA program at PAIU is designed to transform you into a global business leader. Through a curriculum that integrates both theoretical and practical knowledge, you will gain insights into key business disciplines including finance, marketing, operations, and strategic management.This comprehensive approach ensures you are well-prepared to tackle the complexities of the modern business world",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "phd", 
  },
  {
    title: "PhD in Demographics & Social Science",
    info: "Our MSc in Data Science equips you with the necessary skills to analyze and interpret complex data. This program focuses on machine learning, statistical methods, data visualization, and data-driven decision-making, ensuring you are ready to meet the demands of this rapidly evolving field.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "phd", 
  },
  {
    title: "PhD in Economics",
    info: "The MSc in Information Technology at PAIU provides an in-depth understanding of IT systems, software development, and IT project management. This program is ideal for those looking to enhance their technical skills and pursue a career in the ever-evolving tech industry.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "phd", 
  },
  {
    title: "PhD in Education",
    info: "The MSc in Information Technology at PAIU provides an in-depth understanding of IT systems, software development, and IT project management. This program is ideal for those looking to enhance their technical skills and pursue a career in the ever-evolving tech industry.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "phd", 
  },
  {
    title: "PhD in History",
    info: "The MSc in Information Technology at PAIU provides an in-depth understanding of IT systems, software development, and IT project management. This program is ideal for those looking to enhance their technical skills and pursue a career in the ever-evolving tech industry.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "phd", 
  },
  {
    title: "PhD in International Relations",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "phd", 
  },
  {
    title: "PhD in Law",
    info: "The MSc in Information Technology at PAIU provides an in-depth understanding of IT systems, software development, and IT project management. This program is ideal for those looking to enhance their technical skills and pursue a career in the ever-evolving tech industry.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "phd", 
  },
  {
    title: "PhD in Sales and Marketing",
    info: "The MSc in Information Technology at PAIU provides an in-depth understanding of IT systems, software development, and IT project management. This program is ideal for those looking to enhance their technical skills and pursue a career in the ever-evolving tech industry.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "phd", 
  },
  {
    title: "PhD in TESOL",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level: "phd", 
  },

  {
    title: "Doctor of Business Administration",
    info: "Our MBA program at PAIU is designed to transform you into a global business leader. Through a curriculum that integrates both theoretical and practical knowledge, you will gain insights into key business disciplines including finance, marketing, operations, and strategic management.This comprehensive approach ensures you are well-prepared to tackle the complexities of the modern business world",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
    level:"dba"
  },
];

const FeatureTwo = ({ wrapperClass, bgColor, alignment, style }) => {
//   const handleDownload = () => {
//     const link = document.createElement("a");
//     link.href = `${process.env.PUBLIC_URL}/Online-MBA_Program-Brochure_compressed.pdf`;
//     link.download = "Online-MBA_Program-Brochure_compressed.pdf";
//     link.click();
//   };

const location = useLocation(); // Add this line to get current route

  // Filter programs based on the current route
  const filteredItems = FeatureItems.filter((item) => {
    if (location.pathname.includes("master-programs")) {
      return item.level === "masters";
    } else if (location.pathname.includes("bachelors-programs")) {
      return item.level === "bachelors";
    } else if (location.pathname.includes("phd-programs")) {
      // Assuming you have PhD items, you can add them similarly
      return item.level === "phd";
    } else if (location.pathname.includes("dba-program")) {
      // Assuming you have DBA items, you can add them similarly
      return item.level === "dba";
    }
    return false;
  });

   // Determine the className based on the current route
   const columnClass = location.pathname.includes("/dba-program") ? "col-lg-12" : "col-lg-4";



  return (
    <div
      className={`row eduvibe-about-one-service ${
        wrapperClass || "g-5 mt--20"
      }`}
    >
      {filteredItems.map((data, i) => (
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          className={`${columnClass} col-md-4 col-12${style ? " " + style : ""}`}
          animateOnce={true}
          key={i}
        >
          {/* <div
            className={`service-card service-card-3 ${
              alignment ? " " + alignment : "text-left"
            } ${data.shape} ${bgColor || ""} `}
          >
            <div className="inner">
              <div className="content">
                <h6 className="title">
                  <a href={data.link}>{data.title}</a>
                </h6>

                <p className="description">{data.info}</p>
                <Stack spacing={2} pb={2}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <ForumIcon sx={{ height: "30px", width: "30px" }} />
                    <p>{data.lang}</p>
                  </Stack>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CalendarMonthIcon sx={{ height: "30px", width: "30px" }} />
                    <p>{data.duration}</p>
                  </Stack>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <SchoolIcon sx={{ height: "30px", width: "30px" }} />
                    <p>{data.campus}</p>
                  </Stack>
                </Stack>

                <div className="read-more-btn">
                  <a
                    className="btn-transparent sm-size heading-color"
                    href={data.link}
                  >
                    Download Brochure
                    <i className="icon-arrow-right-line-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <Card
            style={{ margin: "auto", borderRadius: "10px" }}
            className={`service-card service-card-3 ${
              alignment ? " " + alignment : "text-left"
            } ${data.shape} ${bgColor || ""} `}
          >
            <Box
              sx={{
                backgroundColor: "#0c0d77",
                padding: "20px",
                borderRadius: "10px 10px 0 0",
                textAlign: "center",
              }}
            >
              <h5
                className="title"
                style={{ color: "white", marginBottom: "0px" }}
              >
                {data.title}
              </h5>
            </Box>
            <CardContent>
              {/* <p className="description">{data.info}</p> */}
              <Stack spacing={2} pb={2}>
                {/* <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <ForumIcon
                    sx={{ height: "30px", width: "30px", color: "#0c0d77" }}
                  />
                  <p>{data.lang}</p>
                </Stack> */}
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  {/* <CalendarMonthIcon
                    
                  /> */}
                  <AccessTimeIcon sx={{ height: "30px", width: "30px", color: "#0c0d77" }} />
                  <p>2 Years (Regular) <br /> 1 Year  (Fast Track)</p>
                </Stack>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <SchoolIcon
                    sx={{ height: "30px", width: "30px", color: "#0c0d77" }}
                  />
                  <p>On-Campus Option <br /> Online Option</p>
                </Stack>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <ClassIcon
                    sx={{ height: "30px", width: "30px", color: "#0c0d77" }}
                  />
                  <p>Weekend Classes Option <br /> Weekday Classes Option</p>
                </Stack>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <SupportIcon
                    sx={{ height: "30px", width: "30px", color: "#0c0d77" }}
                  />
                  <p>French Curriculum</p>
                </Stack>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <VerifiedIcon
                    sx={{ height: "30px", width: "30px", color: "#0c0d77" }}
                  />
                  <p>Recognized by the Ministry of Education</p>
                </Stack>
              </Stack>
              {/* 
                <div className="read-more-btn">
                  <a
                    className="btn-transparent sm-size heading-color"
                    href={data.link}
                  >
                    Download Brochure
                    <i className="icon-arrow-right-line-right"></i>
                  </a></div> */}
              <Link
                    className="btn-transparent sm-size heading-color"
                    to="contact-us"
                    spy={true}
                    smooth={true}
                    duration={200}
                  >
                     Download Brochure
                     <i className="icon-arrow-right-line-right"></i>
                  </Link>
              {/* <a
                className="btn-transparent sm-size heading-color"
                onClick={handleDownload}
              >
                Download Brochure
                <i className="icon-arrow-right-line-right"></i>
              </a> */}
            </CardContent>
          </Card>
        </ScrollAnimation>
      ))}
    </div>
  );
};

export default FeatureTwo;

import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import {
  GroupIcon,
  isSmallScreens,
  ProgressIcon,
} from "../../Constants/Constants";
import { useMediaQuery } from "@mui/material";
import CustomForm from "../form/CustomForm";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const BannerTwo = () => {
  const isSmallScreen = useMediaQuery(isSmallScreens);

  const [degree, setDegree] = useState("");
  const location = useLocation();

  // Degrees to rotate
  const degrees = [
    { name: "Master's in Business Administration (MBA)", level: "Masters" },
    { name: "Masters in Data Science", level: "Masters" },
    { name: "Masters in Information Technology", level: "Masters" },
    { name: "Masters in Cyber Security", level: "Masters" },
    { name: "Masters in Corporate Finance", level: "Masters" },
    { name: "Masters in Marketing Management", level: "Masters" },
    { name: "Masters in Legal Business Studies", level: "Masters" },
    { name: "Masters in Human Resource Management", level: "Masters" },
    
    { name: "Bachelor's in Accounting & Business", level: "Bachelors" },
    { name: "Bachelor's in Business Administration", level: "Bachelors" },
    { name: "Bachelor's in Cyber Security", level: "Bachelors" },
    { name: "Bachelor's in Data Science", level: "Bachelors" },
    { name: "Bachelor's in Hospitality & Tourism", level: "Bachelors" },
    { name: "Bachelor's in Information Technology", level: "Bachelors" },

    { name: "PhD in Data and Cybersecurity", level: "Phd" },
    { name: "PhD in Demographics & Social Science", level: "Phd" },
    { name: "PhD in Economics", level: "Phd" },
    { name: "PhD in Education", level: "Phd" },
    { name: "PhD in History", level: "Phd" },
    { name: "PhD in International Relations", level: "Phd" },
    { name: "PhD in Law", level: "Phd" },
    { name: "PhD in Sales and Marketing", level: "Phd" },
    { name: "PhD in TESOL", level: "Phd" },

    { name: "Doctorate in Business Administration (DBA)", level: "Doctorate" },
  ];

  useEffect(() => {
    // Extract the current pathname
    const path = location.pathname.toLowerCase();

    // Filter the degrees based on the path
    let filteredDegrees;
    if (path.includes("/bachelors-programs")) {
      filteredDegrees = degrees.filter(
        (degree) => degree.level === "Bachelors"
      );
    } else if (path.includes("/phd-programs")) {
      filteredDegrees = degrees.filter((degree) => degree.level === "Phd");
    } else if (path.includes("/dba-program")) {
      filteredDegrees = degrees.filter(
        (degree) => degree.level === "Doctorate"
      );
    } else {
      // Default to Masters
      filteredDegrees = degrees.filter((degree) => degree.level === "Masters");
    }

    // Set the first degree to start the rotation
    setDegree(filteredDegrees[0].name);

    const interval = setInterval(() => {
      setDegree((prevDegree) => {
        const currentIndex = filteredDegrees.findIndex(
          (degree) => degree.name === prevDegree
        );
        const nextIndex = (currentIndex + 1) % filteredDegrees.length;
        return filteredDegrees[nextIndex].name;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [location.pathname]);

  // Parent container animation configuration
  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05, // Stagger animation between each letter
      },
    },
  };

  // Individual letter animation configuration
  const letterVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  // Helper function to break a string into individual characters
  const renderLetters = (text) => {
    return text.split("").map((char, index) => (
      <motion.span key={index} variants={letterVariants}>
        {char}
      </motion.span>
    ));
  };

  return (
    <div className="slider-area banner-style-2 bg-image d-flex align-items-center">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="inner">
              <div className="content">
                <div className="title-container" style={{height:"215px"}}>
                <h1 className="title">
                  Study our globally recognized & accredited
                  <motion.span
                    key={degree}
                    style={{ color: "#0c0d77", display: "inline-block" }}
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                  >
                    {renderLetters(`${degree}`)}
                  </motion.span>
                </h1>
                </div>
               
                {/* <p className="description">
                  By enrolling in our master's program, you will be prepared to
                  navigate the complexities of an ever-evolving workforce,
                  ensuring success in your chosen field. Whether you aim to lead
                  in business, innovate in technology, or secure digital
                  infrastructures, PAIU provides the comprehensive education and
                  practical experience required to achieve your career goals.
                </p> */}
                {/* <div>
                  <Link to="contact-us" spy={true} smooth={true} duration={200}>
                    <button class="btnn">
                      <i class="animation"></i>Apply for scholarship
                      <i class="animation"></i>
                    </button>
                  </Link>
                </div> */}
                <div className="p-4">
                  <CustomForm />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner-thumbnail">
              <img
                className="girl-thumb"
                src="/images/brand-logos/hero-pic-sec.webp"
                alt="Girl Images"
              />
            </div>
            {/* <div className="banner-bg d-lg-block d-none">
              <img
                className="girl-bg"
                src="/images/banner/banner-02/girl-bg.png"
                alt="Girl Background"
              />
            </div> */}
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block">
          <div className="shape-image shape-image-2 bounce-slide d-none d-lg-block">
            <ProgressIcon
              width={isSmallScreen ? 70 : 123}
              height={isSmallScreen ? 70 : 123}
            />
          </div>
          <div className="shape-image shape-image-3 bounce-slide d-none d-lg-block">
            <GroupIcon
              width={isSmallScreen ? 70 : 109}
              height={isSmallScreen ? 70 : 135}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .btnn {
          outline: 0;
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          background: #0c0d77;
          min-width: 200px;
          border: 0;
          border-radius: 4px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
          padding: 16px 20px;
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 1.2px;
          overflow: hidden;
          cursor: pointer;
        }

        .btnn:hover {
          opacity: 0.95;
        }

        .btnn .animation {
          border-radius: 100%;
          animation: ripple 0.6s linear infinite;
        }

        @keyframes ripple {
          0% {
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
              0 0 0 20px rgba(255, 255, 255, 0.1),
              0 0 0 40px rgba(255, 255, 255, 0.1),
              0 0 0 60px rgba(255, 255, 255, 0.1);
          }

          100% {
            box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
              0 0 0 40px rgba(255, 255, 255, 0.1),
              0 0 0 60px rgba(255, 255, 255, 0.1),
              0 0 0 80px rgba(255, 255, 255, 0);
          }
        }
      `}</style>
    </div>
  );
};

export default BannerTwo;
